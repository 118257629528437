import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div>Designed and Built by Kayla Lee. </div>
      <div> All rights reserved. ©</div>
    </footer>
  )
}

export default Footer