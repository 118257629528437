import React from 'react'
import './education.css'
import {MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox} from 'react-icons/md'

const Education = () => {
  return (
    <section id='education'>
      <h2>My Education</h2>

      <div className='container education__container'>
        <div className="education__school">
          <h2>
            University Coursework
          </h2>
          <div className="education__content">
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Data Structures & Algorithms</h4>
              </div>
            </article>
            <article className='education__details'>
            <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Computational Machine Learning</h4>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Programming/Intro Data Structures</h4>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Discrete Mathematics</h4>
              </div>
            </article>
            {/* <article className='education__details'>
              <MdOutlineCheckBox size={20} className='education__details-icon'/>
              <div>
                <h4>EECS 183</h4>
                <small>Intro to Computer Science</small>
              </div>
            </article> */}
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Computer Organization</h4>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Database Management</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="education__outside">
          <h2>
            Independent Coursework
          </h2>
          <div className="education__content">

            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Machine Learning</h4>
                <small>Stanford Coursera Course</small>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>AI/ML Basics</h4>
                <small>SureStart Curriculum</small>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>Linear Algebra</h4>
                <small>University of Massachusetts</small>
              </div>
            </article>
            <article className='education__details'>
              <MdOutlineCheckBox className='education__details-icon'/>
              <div>
                <h4>SAP Foundations</h4>
                <small>Ernst & Young Bronze Badge</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Education